'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.units.factory:UnitMarkersManager

 # @description

###
angular
  .module 'mundoAdmin.units'
  .factory 'UnitMarkersManager', [
    'MundoDefaultManager'
    (MundoDefaultManager) ->
      UnitMarkersManagerBase = new MundoDefaultManager()
      UnitMarkersManagerBase.conf.url = 'services/units/markers'

      UnitMarkersManagerBase
  ]
